@use 'sass:math';
@use './theme';

@mixin link {
    cursor: pointer;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

@mixin space($space) {
    & > * {
        margin-inline-end: $space;

        &:last-child {
            margin-inline-end: 0;
        }
    }
}

@mixin space-vertical($space) {
    & > * {
        margin-bottom: $space;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@mixin separator($space) {
    & > * {
        margin-inline-end: math.div($space, 2);

        &:after {
            display: inline-block;
            content: '|';
            margin-inline-start: math.div($space, 2);
        }

        &:last-child {
            margin-inline-end: 0;

            &:after {
                display: none;
            }
        }
    }
}

@mixin thumb-icon($with) {
    color: theme.$text-mid-gray;
    cursor: pointer;
    width: $with;

    &:hover {
        color: theme.$action-button-color--hover;
        transform: scale(1.2);
    }
}

@mixin custom-scroll {
    &::-webkit-scrollbar {
        width: theme.$scroll-bar-width;
        background: theme.$scroll-bar-background;
    }

    &::-webkit-scrollbar-thumb {
        background: theme.$scroll-bar-track;
        border-radius: 3px;
    }
}

@mixin answer-wrapper {
    display: flex;
    border-radius: 4px;
    padding: 16px theme.$answers-answer-padding-x 8px;

    &:focus {
        outline: 1px solid theme.$text-mid-gray;
    }
}

@mixin toast {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;

    & > *:first-child {
        flex: 0 0 16px;
    }
}

@mixin suggestion-opacity {
    background-color: theme.$light-blue;
    transition: all 0.3s linear;
    border-radius: 4px;
}

@mixin overflow-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@mixin overflow-ellipsis-multiline($lines-count) {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-count;
    overflow: hidden;
}

@mixin dimmer($height: 56px) {
    position: absolute;
    left: 0;
    bottom: 0;
    height: $height;
    width: calc(100% - #{theme.$scroll-bar-width});
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.86) 50%, #ffffff 100%);
}

@mixin placeholder-wrapper($space) {
    @include space-vertical($space);

    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 33px 30px;
    text-align: center;

    color: theme.$text-mid-gray;
}

@mixin breakpoint($breakpoint) {
    @if map-has-key(theme.$breakpoints, $breakpoint) {
        @media screen and (min-width: map-get(theme.$breakpoints, $breakpoint)) {
            @content;
        }
    } @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys(theme.$breakpoints)}.";
    }
}

@mixin button-reset {
    border: 0;
    background: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    appearance: none;

    &[disabled] {
        color: theme.$blue-gray-200;

        &:hover {
            cursor: not-allowed;
            color: theme.$blue-gray-200;
        }
    }
}

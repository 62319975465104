@use '../../../styles/theme';
@use '../../../styles/mixins';

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.version {
    color: theme.$text-light-gray;
    font-weight: 500;
    font-size: 0.7em;
    line-height: 24px;
}

.body {
    @include mixins.space-vertical(10px);

    display: flex;
    flex-direction: column;
}

.footer {
    composes: footer from '../Modals.module.scss';
}

.checkboxWrapper {
    user-select: none;

    span {
        font-size: inherit;
    }

    input + span {
        // this is a checkbox element
        margin-top: 0.25em;
        align-self: flex-start;
    }

    .label {
        color: theme.$text-light-gray;
        font-size: 1.4em;
        line-height: 1.5;

        b {
            color: theme.$text-control;
            font-weight: 500;
        }
    }
}
